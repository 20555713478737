import {CurrencyDto} from "../modules/features/currencies/currencies-api";
import {ChargeGqlDto} from "../modules/features/order/order-api";

export function getFormattedPrice(
  price: number | string | null | undefined,
  currency?: CurrencyDto,
) {
  if (typeof price == "string") price = parseFloat(price);
  if (typeof price == "number") {
    if (currency && currency.symbol && currency.decimalPlaces)
      return currency.symbol + price.toFixed(currency.decimalPlaces);
    return "$" + price.toFixed(2);
  }
  return "";
}

export function getFormattedPriceByInvoice(
  charge: ChargeGqlDto | null | undefined,
  hasInvoice: boolean,
  showEstimated?: boolean,
  currency?: CurrencyDto,
) {
  if (!hasInvoice && charge?.description == "Shipping")
    if (showEstimated && charge?.rate?.estimatedCost) {
      return charge?.rate?.estimatedCost;
    } else {
      return getFormattedPrice(charge?.totalAmount, currency);
    }
  if (!hasInvoice && charge?.description == "Discount")
    return `${(charge.price! * -100).toFixed(2)} %`;
  if (typeof charge?.totalAmount == "number") {
    return getFormattedPrice(charge?.totalAmount, currency);
  }
  return "";
}
