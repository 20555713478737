import {Field, Form, FormikProps} from "formik";
import dayjs, {Dayjs} from "dayjs";
import {Autocomplete, Grid, useMediaQuery} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {TextField} from "formik-mui";
import {t} from "i18next";
import {LoadingButton} from "@mui/lab";
import React from "react";
import {getFormattedDayjsTime} from "../../../utils/dayjs.utils";
import {useTheme} from "@mui/system";

export type PickupFormValues = {
  pickupDate?: Dayjs | null;
  latestTime: string | null | undefined;
  readyTime: string | null | undefined;
};

export interface PickupFormProps {
  formikProps: FormikProps<PickupFormValues>;
  availableOptions: any;
  isLoading: boolean;
  isError?: boolean;
  handleCheckAvailability: any;
  i18nKeyPrefix?: string;
  submitBtnLabel?: any;
  onCancel?: any;
}

function getFirstAvailableDate(): Dayjs {
  let date = dayjs().add(1, "day"); // Start from tomorrow (minDate)
  while (date.day() === 0 || date.day() === 6) {
    date = date.add(1, "day"); // Skip weekends (0 = Sunday, 6 = Saturday)
  }
  return date;
}

export const PickupFormInitialValues: PickupFormValues = {
  pickupDate: getFirstAvailableDate(),
  readyTime: null,
  latestTime: null,
};

export default function PickupForm({
  formikProps,
  availableOptions,
  isLoading,
  isError = false,
  handleCheckAvailability,
  i18nKeyPrefix = "delivery.pickup.",
  submitBtnLabel = t(i18nKeyPrefix + "continue"),
  onCancel,
}: PickupFormProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const selectedOption = availableOptions?.find(
    (x: any) =>
      x.pickupDate === formikProps?.values?.pickupDate?.format("YYYY-MM-DD") &&
      x.available,
  );

  const readyTimeOptions = selectedOption?.readyTimeOptions ?? [];
  const latestTimeOptions = selectedOption?.latestTimeOptions ?? [];
  const defaultReadyTime =
    selectedOption?.defaultReadyTime ?? readyTimeOptions[0] ?? null;
  const defaultLatestTime =
    selectedOption?.defaultLatestTimeOptions ?? latestTimeOptions[0] ?? null;

  let formikChangedValues = formikProps?.values;

  if (!isLoading && selectedOption) {
    if (
      !formikProps?.values?.readyTime &&
      defaultReadyTime &&
      readyTimeOptions.includes(defaultReadyTime)
    ) {
      formikChangedValues = {
        ...formikChangedValues,
        readyTime: defaultReadyTime,
      };
    }
    if (
      formikProps?.values?.readyTime &&
      !readyTimeOptions.includes(formikProps?.values?.readyTime)
    ) {
      formikChangedValues = {...formikChangedValues, readyTime: null};
    }

    if (
      !formikProps?.values?.latestTime &&
      defaultLatestTime &&
      latestTimeOptions.includes(defaultLatestTime)
    ) {
      formikChangedValues = {
        ...formikChangedValues,
        latestTime: defaultLatestTime,
      };
    }
    if (
      formikProps?.values?.latestTime &&
      !latestTimeOptions.includes(formikProps?.values?.latestTime)
    ) {
      formikChangedValues = {...formikChangedValues, latestTime: null};
    }
  }

  if (formikChangedValues !== formikProps?.values) {
    formikProps?.setValues(formikChangedValues);
  }

  return (
    <Form>
      <Grid container gap={3}>
        <Grid container gap={2} sx={{flexWrap: {md: "nowrap", xs: "wrap"}}}>
          <Grid xs={12} md={4} lg={4}>
            <DatePicker
              value={formikProps?.values?.pickupDate}
              onChange={(value) => {
                formikProps?.setFieldValue("pickupDate", value);
                handleCheckAvailability({
                  ...formikProps?.values,
                  pickupDate: value,
                });
              }}
              minDate={dayjs().add(1, "day")}
              maxDate={dayjs().add(8, "days")}
              shouldDisableDate={(date) => {
                const day = date.day(); // 0 = Sunday, 6 = Saturday
                return day === 0 || day === 6;
              }}
              renderInput={(params: any) => (
                <Field
                  {...params}
                  component={TextField}
                  fullWidth
                  variant="outlined"
                  label={t(i18nKeyPrefix + "pickupDateLabel")}
                  data-testid="input-pickup-date"
                  name="pickupDate"
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4}>
            <Autocomplete
              options={readyTimeOptions}
              disabled={isLoading}
              value={formikProps?.values?.readyTime}
              getOptionLabel={(option: any) => getFormattedDayjsTime(option)}
              size={isDesktop ? "medium" : "small"}
              renderInput={(params: any) => (
                <Field
                  {...params}
                  component={TextField}
                  fullWidth
                  variant="outlined"
                  label={t(i18nKeyPrefix + "readyTimeLabel")}
                  data-testid="input-ready-time"
                  name="readyTime"
                />
              )}
              onChange={(_: any, value: any | null) => {
                formikProps?.setFieldValue("readyTime", value);
              }}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4}>
            <Autocomplete
              options={latestTimeOptions}
              disabled={isLoading}
              value={formikProps?.values?.latestTime}
              getOptionLabel={(option: any) => getFormattedDayjsTime(option)}
              size={isDesktop ? "medium" : "small"}
              renderInput={(params: any) => (
                <Field
                  {...params}
                  component={TextField}
                  fullWidth
                  variant="outlined"
                  label={t(i18nKeyPrefix + "latestTimeLabel")}
                  data-testid="input-latest-time"
                  name="latestTime"
                />
              )}
              onChange={(_: any, value: any | null) => {
                formikProps?.setFieldValue("latestTime", value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container gap={2}>
          <LoadingButton
            type={"submit"}
            variant="contained"
            color="secondary"
            loading={formikProps?.isSubmitting}
            disabled={isLoading || !formikProps?.isValid || isError}
            sx={{minWidth: "200px"}}
          >
            {submitBtnLabel}
          </LoadingButton>
          {onCancel && (
            <LoadingButton
              variant="outlined"
              color="primary"
              loading={formikProps?.isSubmitting}
              onClick={onCancel}
              sx={{minWidth: "200px"}}
            >
              {t(i18nKeyPrefix + "cancel")}
            </LoadingButton>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
