import React, {useCallback} from "react";
import {Button, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ModalForm from "../Modal/components/modalForm";

interface UseModalFormProps {
  handleCancel?: () => void;
  handleConfirm?: () => void;
  confirmText?: any;
  cancelText?: any;
  text?: any;
  isOpen: boolean;
  content?: any;
}

export const useModalForm = ({
  handleCancel,
  handleConfirm,
  text = "Are you sure?",
  confirmText = "Confirm",
  cancelText = "Cancel",
  isOpen,
  content,
}: UseModalFormProps) => {
  const renderModal = useCallback(() => {
    return (
      <ModalForm open={isOpen}>
        <Grid>
          {content ?? (
            <Typography
              data-testid="delete-address-modal-description"
              variant="subtitle1"
              component="h5"
            >
              {text}
            </Typography>
          )}

          {handleCancel && (
            <Button
              sx={{mt: 2, mr: {xs: 1, md: 2}}}
              onClick={handleCancel}
              variant="outlined"
              color="primary"
            >
              {cancelText}
            </Button>
          )}
          {handleConfirm && (
            <Button
              sx={{mt: 2}}
              onClick={handleConfirm}
              variant="contained"
              color="secondary"
            >
              {confirmText}
            </Button>
          )}
        </Grid>
      </ModalForm>
    );
  }, [
    isOpen,
    handleCancel,
    handleConfirm,
    text,
    confirmText,
    cancelText,
    content,
  ]);

  return {renderModal};
};
