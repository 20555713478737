import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import i18next from "i18next";
import {ChargeDto} from "../../../features/order/order-api";
import {TotalCostProps} from "../../components/interfaces";
import {getFormattedPrice} from "../../../../utils/formatCurrency.utils";
import {getCustomValue} from "../../../../utils/helper.utils";
import {chargeIsDiscount} from "../../../common/utils/charge.utils";
import {useGetAdditionalServices} from "../hooks/useGetAdditionalServices";
import {Field, Formik} from "formik";
import {TextField} from "formik-mui";
import {LoadingButton} from "@mui/lab";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";
import toast from "react-hot-toast";
import {toastError} from "../../../common/utils/toastMessages";
import {useAppSelector} from "../../../store";

export const boxStyle = {
  backgroundColor: "white",
  border: "1px solid #D8E6FB",
  borderRadius: "16px",
  boxShadow: "0px 0px 8px rgba(15, 74, 132, 0.1)",
  p: 4,
};

const TotalCostParcels = ({
  deliveryCostPerUnit,
  sx = null,
  charges,
  consolidationItemCode = "",
  insuranceItemCode = "",
  showTotalCost = false,
  headerColor = null,
  applyPromoCode,
  currentOrder,
}: TotalCostProps) => {
  const {t} = useTranslation();

  const orderData = useAppSelector((state) => state.parcelState);
  const pickupItemCode =
    getCustomValue(
      orderData?.order?.deliveryToTrtMethod?.carrier?.customValues,
      "pickup_accounting_item_code",
    ) ?? "";

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [insuranceCharge, setInsuranceCharge] =
    useState<ChargeDto | null | undefined>(null);
  const [discountCharge, setDiscountCharge] =
    useState<ChargeDto | null | undefined>(null);
  const [consolidationCharge, setConsolidationCharge] =
    useState<ChargeDto | null | undefined>(null);
  const [pickupCharge, setPickupCharge] =
    useState<ChargeDto | null | undefined>(null);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [promoCode, setPromoCode] = useState<string>(
    currentOrder?.customValues?.promoCode,
  );
  const [promoCodeLoad, setPromoCodeLoad] = useState<boolean>(false);

  const [additionalCharges, setAdditionalCharges] = useState<
    ChargeDto[] | undefined
  >([]);

  const {additionalServices: accountingItems} = useGetAdditionalServices();

  const validatePromoCodeWorkflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.validatePromoCodeWorkflowId,
  );
  const [runWorkflow] = useWorkflowExecutionMutation();

  useEffect(() => {
    if (showTotalCost) {
      let total = 0;

      charges?.forEach((charge) => {
        total += charge.totalAmount ?? 0;
      });

      setTotalCost(total);
    }
  }, [charges, showTotalCost]);

  useEffect(() => {
    const insCharge = charges?.find(
      (x) => x.accountingItemCode == insuranceItemCode,
    );
    setInsuranceCharge(insCharge);
    const disCharge = charges?.find((x) => x.applyBy == "Calculated");
    setDiscountCharge(disCharge);
    const consCharge = charges?.find(
      (x) =>
        consolidationItemCode && x.accountingItemCode == consolidationItemCode,
    );
    setConsolidationCharge(consCharge);
    const pickupCharge = charges?.find(
      (x) => pickupItemCode && x.accountingItemCode == pickupItemCode,
    );
    setPickupCharge(pickupCharge);
    const addCharges = charges?.filter((charge) => {
      return (
        charge.accountingItemCode &&
        charge.accountingItemCode !== insuranceItemCode &&
        charge.accountingItemCode !== consolidationItemCode &&
        charge.accountingItemCode !== pickupItemCode &&
        !chargeIsDiscount(charge) &&
        charge.applyBy !== "Calculated"
      );
    });
    setAdditionalCharges(addCharges);
  }, [charges, consolidationItemCode, insuranceItemCode]);

  async function confirmPromoCode() {
    setPromoCodeLoad(true);
    const workflowVariables: any = {
      rateId: currentOrder.deliveryMethod?.rateId,
      parcelsPortal: true,
      promoCode: promoCode,
    };
    const executeQuoteWorkflowApiArgs: WorkflowExecutionApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      workflowId: validatePromoCodeWorkflowId,
      values: {variables: {...workflowVariables}},
    };
    const response: any = await runWorkflow(executeQuoteWorkflowApiArgs);
    if (response.data?.outputs) {
      await applyPromoCode(promoCode);
      toast.success(t("delivery.additionalServices.promoCodeApplied"));
    }
    if (response.error) {
      setPromoCode("");
      toastError(response);
    }
    setPromoCodeLoad(false);
  }

  return (
    <Box sx={sx ?? boxStyle}>
      <Grid container direction="column">
        <Typography
          variant={isDesktop ? "h2" : "h3"}
          mb={3}
          color={headerColor ?? ""}
        >
          {showTotalCost
            ? t("delivery.totalCost.shippingCost")
            : t("delivery.totalCost.estimatedCost")}
        </Typography>

        <Grid container direction="row" justifyContent="space-between">
          <Typography sx={{fontWeight: 600}} variant="body1">
            {t("delivery.additionalServices.shippingCost")}
          </Typography>
          <Grid borderBottom="1px dotted #D8E6FB" flexGrow={1} height="20px" />
          <Typography
            sx={{fontWeight: 600}}
            data-testid="delivery-cost"
            variant="body4"
          >
            {deliveryCostPerUnit}
          </Typography>
        </Grid>

        {insuranceCharge && !isNaN(insuranceCharge?.totalAmount as any) && (
          <Grid
            sx={{pt: 3}}
            container
            direction="row"
            justifyContent="space-between"
          >
            <Typography sx={{fontWeight: 600}} variant="body1">
              {t("delivery.additionalServices.insurance")}
            </Typography>
            <Grid
              borderBottom="1px dotted #D8E6FB"
              flexGrow={1}
              height="20px"
            />
            <Typography
              sx={{fontWeight: 600}}
              data-testid="insurance-cost"
              variant="body4"
            >
              {getFormattedPrice(insuranceCharge.totalAmount)}
            </Typography>
          </Grid>
        )}

        {discountCharge && !isNaN(discountCharge?.price as any) && (
          <Grid
            sx={{pt: 3}}
            container
            direction="row"
            justifyContent="space-between"
          >
            <Typography sx={{fontWeight: 600}} variant="body1">
              {t("delivery.additionalServices.discount")}
            </Typography>
            <Grid
              borderBottom="1px dotted #D8E6FB"
              flexGrow={1}
              height="20px"
            />
            <Typography
              sx={{fontWeight: 600}}
              data-testid="discount-cost"
              variant="body4"
            >
              {discountCharge.price
                ? `${(Math.abs(discountCharge?.price) * 100).toFixed(2)}%`
                : ""}
            </Typography>
          </Grid>
        )}

        {additionalCharges?.length !== 0 && (
          <>
            <Grid
              sx={{pt: 3}}
              container
              direction="row"
              justifyContent="space-between"
            >
              <Typography sx={{fontWeight: 600}} variant="body1">
                {t("delivery.statusBar.additionalServices")}
              </Typography>
            </Grid>
            {additionalCharges?.map((data: any, index: number) => {
              const accountingItem = accountingItems?.find(
                (x) => x.accountingItemId === data.accountingItemId,
              );
              return (
                <Grid
                  sx={{pt: 1}}
                  key={index}
                  container
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography variant="body1">
                    {getCustomValue(
                      accountingItem?.customValues,
                      `service_description_${i18next.language}`,
                    ) ?? ""}
                  </Typography>
                  <Grid
                    borderBottom="1px dotted #D8E6FB"
                    flexGrow={1}
                    height="20px"
                  />
                  <Typography
                    sx={{fontWeight: 600}}
                    data-testid="approved-additional-services-cost"
                    variant="body4"
                  >
                    {getFormattedPrice(data.totalAmount)}
                  </Typography>
                </Grid>
              );
            })}
          </>
        )}
        {pickupCharge && !isNaN(pickupCharge?.totalAmount as any) && (
          <Grid
            sx={{pt: 3}}
            container
            direction="row"
            justifyContent="space-between"
          >
            <Typography sx={{fontWeight: 600}} variant="body1">
              {getCustomValue(
                pickupCharge.accountingItem?.customValues,
                `service_description_${i18next.language}`,
              ) ?? ""}
            </Typography>
            <Grid
              borderBottom="1px dotted #D8E6FB"
              flexGrow={1}
              height="20px"
            />
            <Typography
              sx={{fontWeight: 600}}
              data-testid="pickup-cost"
              variant="body4"
            >
              {pickupCharge.totalAmount
                ? getFormattedPrice(pickupCharge.totalAmount)
                : ""}
            </Typography>
          </Grid>
        )}
        {consolidationCharge &&
          !isNaN(consolidationCharge?.totalAmount as any) && (
            <Grid
              sx={{pt: 3}}
              container
              direction="row"
              justifyContent="space-between"
            >
              <Typography sx={{fontWeight: 600}} variant="body1">
                {getCustomValue(
                  consolidationCharge.accountingItem?.customValues,
                  `service_description_${i18next.language}`,
                ) ?? ""}
              </Typography>
              <Grid
                borderBottom="1px dotted #D8E6FB"
                flexGrow={1}
                height="20px"
              />
              <Typography
                sx={{fontWeight: 600}}
                data-testid="consolidation-cost"
                variant="body4"
              >
                {consolidationCharge.totalAmount
                  ? getFormattedPrice(consolidationCharge.totalAmount)
                  : ""}
              </Typography>
            </Grid>
          )}
        {showTotalCost && (
          <Grid
            sx={{pt: 3}}
            container
            direction="row"
            justifyContent="space-between"
          >
            <Typography sx={{fontWeight: 600}} variant="body1">
              <Trans i18nKey={"delivery.totalCost.total"}>Total Cost</Trans>
            </Typography>
            <Grid
              borderBottom="1px dotted #D8E6FB"
              flexGrow={1}
              height="20px"
            />
            <Typography
              sx={{fontWeight: 600}}
              data-testid="total-cost"
              variant="body4"
            >
              {getFormattedPrice(totalCost)}
            </Typography>
          </Grid>
        )}
        {applyPromoCode && (
          <div
            style={{
              display: isDesktop ? "flex" : "block",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Formik
              initialValues={{promoCode}}
              onSubmit={() => applyPromoCode()}
            >
              <Field
                fullWidth
                component={TextField}
                name={`promoCode`}
                size={isDesktop ? "normal" : "small"}
                label={t("delivery.additionalServices.promoCode")}
                placeholder={t("delivery.additionalServices.promoCode")}
                data-testid="input-promocode"
                style={{
                  marginRight: "20px",
                  maxWidth: "200px",
                }}
                value={promoCode}
                onChange={(e: any) => {
                  setPromoCode(e.target.value);
                }}
              />
            </Formik>
            <LoadingButton
              size={"small"}
              variant={"outlined"}
              color={"primary"}
              sx={{height: "40px", textTransform: "none", maxWidth: "100px"}}
              loading={promoCodeLoad}
              onClick={confirmPromoCode}
              disabled={!promoCode || promoCode == ""}
            >
              <Trans i18nKey={"delivery.additionalServices.promoCodeApply"}>
                Apply
              </Trans>
            </LoadingButton>
          </div>
        )}
      </Grid>
    </Box>
  );
};

export default TotalCostParcels;
