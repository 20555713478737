import {Helmet} from "react-helmet";
import React, {useCallback, useEffect} from "react";
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {t} from "i18next";
import {useTheme} from "@mui/system";
import {getCustomValue} from "../../../utils/helper.utils";
import {useAppSelector} from "../../store";
import {useCheckAvailabilityWorkflow} from "./hooks/useCheckAvailabilityWorkflow";
import {Formik, FormikProps} from "formik";
import {useDispatch} from "react-redux";
import * as yup from "yup";
import dayjs from "dayjs";
import {getFormattedPrice} from "../../../utils/formatCurrency.utils";
import {setPickup} from "../../features/parcel/parcel-slice";
import PickupForm, {
  PickupFormInitialValues,
  PickupFormValues,
} from "./pickupForm";
import {Trans} from "react-i18next";

export interface PickupFragmentProps {
  handleNext: any;
  pageTitle: any;
}

export default function PickupFragment({
  handleNext,
  pageTitle,
}: PickupFragmentProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const i18nKeyPrefix = "delivery.pickup.";

  const orderData = useAppSelector((state: any) => state.parcelState);
  const pickupCheckAvailabilityWorkflowId = getCustomValue(
    orderData?.order?.deliveryToTrtMethod?.carrier?.customValues,
    "pickup_check_availability_workflow_id",
  );
  const shippingFromContactAddressId =
    orderData?.order?.contactAddressValues?.contactAddressId;

  const {checkAvailability, isLoading, isError} = useCheckAvailabilityWorkflow(
    pickupCheckAvailabilityWorkflowId,
  );

  const [availableOptions, setAvailableOptions] = React.useState<any>(null);

  const initialValues = orderData?.pickup;
  const pickupFormInitialValues: PickupFormValues = {
    pickupDate: initialValues?.pickupDate
      ? dayjs(initialValues?.pickupDate)
      : PickupFormInitialValues.pickupDate,
    readyTime: initialValues?.readyTime ?? PickupFormInitialValues.readyTime,
    latestTime: initialValues?.latestTime ?? PickupFormInitialValues.latestTime,
  };

  const handleCheckAvailability = useCallback(
    (values: PickupFormValues) => {
      if (checkAvailability && shippingFromContactAddressId) {
        checkAvailability({
          contactAddressId: shippingFromContactAddressId,
          dispatchDate: values?.pickupDate
            ? values?.pickupDate?.format("YYYY-MM-DD")
            : dayjs().format("YYYY-MM-DD"),
        })?.then((options) => {
          setAvailableOptions(options ?? []);
        });
      } else setAvailableOptions([]);
    },
    [checkAvailability, shippingFromContactAddressId],
  );

  useEffect(() => {
    if (handleCheckAvailability) {
      handleCheckAvailability(pickupFormInitialValues);
    }
  }, [handleCheckAvailability]);

  const pickupAmount = getFormattedPrice(
    orderData?.order?.deliveryMethod?.pickupAmount,
  );

  const renderForm = useCallback(
    (formikProps: FormikProps<PickupFormValues>) => (
      <PickupForm
        formikProps={formikProps}
        availableOptions={availableOptions}
        handleCheckAvailability={handleCheckAvailability}
        isLoading={isLoading ?? true}
        isError={isError}
        i18nKeyPrefix={i18nKeyPrefix}
      />
    ),
    [isLoading, availableOptions, handleCheckAvailability],
  );

  const handleSubmit = (values: PickupFormValues) => {
    dispatch(
      setPickup({
        values: {
          ...values,
          pickupDate: values?.pickupDate?.format("YYYY-MM-DD"),
        },
      }),
    );
    if (handleNext) handleNext();
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {!isDesktop ? (
        <Typography variant={"h2"} textTransform={"capitalize"} mt={3} mb={2}>
          {t(i18nKeyPrefix + "pageTitle")}
        </Typography>
      ) : null}
      <Grid>
        {pickupAmount && (
          <Grid container mb={3}>
            <Typography variant={"h3"}>
              <Trans i18nKey={i18nKeyPrefix + "cost"}>
                Pickup cost: {{pickupAmount}}
              </Trans>
            </Typography>
          </Grid>
        )}
        <Formik
          initialValues={pickupFormInitialValues}
          validationSchema={() =>
            yup.object().shape({
              pickupDate: yup.object().required(),
              readyTime: yup.string().required().nullable(),
              latestTime: yup.string().required().nullable(),
            })
          }
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Grid container>
              <Grid lg={9} md={9} xs={12}>
                {renderForm(formikProps)}
              </Grid>
            </Grid>
          )}
        </Formik>
      </Grid>
    </>
  );
}
