import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import BoxButton from "../../common/components/boxButton";
import {DeliveryConfiguration} from "../delivery.module";
import {deliveryCostPerUnit} from "../AdditionalServices/components/additionalServiceFragment";
import {useAppSelector} from "../../store";
import {useInsuranceGet} from "../hooks/UseInsuranceGet";
import {toastError} from "../../common/utils/toastMessages";
import {ChargeDto, CreateChargeCommand} from "../../features/order/order-api";
import {
  setAdditionalServices,
  setCharges,
  setOrderCustomValues,
} from "../../features/parcel/parcel-slice";
import AdditionalServicesListParcels from "./components/additionalServicesListParcels.component";
import TotalCostParcels from "./components/totalCostParcels.component";
import {useGetAdditionalServices} from "./hooks/useGetAdditionalServices";
import {useGetParcelQuote} from "../hooks/useGetParcelQuote";

type InsuranceFragmentProps = {
  pageTitle: string;
  handleNext: () => void;
  config: DeliveryConfiguration;
};
export const InsuranceFragment = ({
  pageTitle,
  handleNext,
  config,
}: InsuranceFragmentProps) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const orderData = useAppSelector((state) => state.parcelState);

  const thisOrder: any = orderData.order;

  const [insurance, setInsurance] = useState<any>();
  const [insuranceState, setInsuranceState] = useState<boolean>(true);
  const [showNumberValidationError, setShowNumberValidationError] =
    useState(false);
  const [showRequiredValidationError, setShowRequiredValidationError] =
    useState(true);
  const [showFormatValidationError, setShowFormatValidationError] =
    useState(false);

  const [insuranceAmount, setInsuranceAmount] = useState<number | null>(
    thisOrder?.charges?.find(
      (x: CreateChargeCommand) => x.values?.description === "Insurance",
    )?.values?.quantity ?? null,
  );

  const {
    insuranceData,
    isLoading,
    isSuccess,
    isError,
    error,
    isLoadingCurrency,
    isErrorCurrency,
    errorCurrency,
  } = useInsuranceGet(config?.insuranceAccountingItemCode ?? "");

  const {getQuote} = useGetParcelQuote();

  const {additionalServices, isLoading: isLoadingAdditionalServices} =
    useGetAdditionalServices();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [promoCode, setPromoCode] = useState<string>(
    thisOrder?.customValues?.promoCode,
  );

  const handleClick = () => {
    if (
      insuranceState &&
      (showFormatValidationError || showNumberValidationError)
    ) {
      setInsuranceAmount(null);
      setShowFormatValidationError(false);
      setShowNumberValidationError(false);
    }
    setInsuranceState((prev) => !prev);
  };

  const handleContinue = () => {
    if (
      insuranceState &&
      (insuranceAmount === undefined ||
        insuranceAmount === null ||
        insuranceAmount <= 0)
    ) {
      if (insuranceAmount === undefined || insuranceAmount === null) {
        setShowRequiredValidationError(true);
        setShowNumberValidationError(false);
      } else {
        setShowNumberValidationError(true);
        setShowRequiredValidationError(false);
      }
    } else {
      if (!showFormatValidationError) handleNext();
    }
  };

  useEffect(() => {
    if (isErrorCurrency) {
      toastError(errorCurrency);
    }
  }, [isLoadingCurrency]);

  useEffect(() => {
    if (isSuccess) {
      setInsurance(insuranceData);
    }
    if (isError) {
      toastError(error);
    }
  }, [isLoading]);

  useEffect(() => {
    if (
      insuranceState &&
      (insuranceAmount === undefined ||
        insuranceAmount === null ||
        insuranceAmount <= 0)
    ) {
      if (insuranceAmount === undefined || insuranceAmount === null) {
        setShowRequiredValidationError(true);
        setShowNumberValidationError(false);
      } else {
        setShowNumberValidationError(true);
        setShowRequiredValidationError(false);
      }
    } else {
      setShowRequiredValidationError(false);
      setShowNumberValidationError(false);
    }
  }, [insuranceState, insuranceAmount]);

  const [calculatedCharges, setCalculatedCharges] = useState<ChargeDto[]>([]);

  useEffect(() => {
    if (
      insurance &&
      !showNumberValidationError &&
      !showRequiredValidationError &&
      !showFormatValidationError
    ) {
      const currentAdditionalServices = {...orderData.additionalServices};
      if (insuranceAmount && insuranceState) {
        currentAdditionalServices[insurance.itemCode] = insuranceAmount;
      } else {
        delete currentAdditionalServices[insurance.itemCode];
      }
      dispatch(
        setAdditionalServices({additionalServices: currentAdditionalServices}),
      );
    }
  }, [
    insuranceAmount,
    insurance,
    insuranceState,
    showNumberValidationError,
    showRequiredValidationError,
    showFormatValidationError,
  ]);

  useEffect(() => {
    const values = {promoCode: thisOrder?.customValues?.promoCode};
    getQuote(values)?.then((charges) => {
      if (charges) {
        setCalculatedCharges(charges);
        const chargesCommands = charges?.map((charge) => {
          const command: CreateChargeCommand = {
            organizationId: process.env
              .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
            values: {
              ...charge,
              chargeStatus:
                charge.accountingItemCode == config?.insuranceAccountingItemCode
                  ? "Open"
                  : "Pending",
            },
          };
          return command;
        });
        dispatch(setCharges({charges: chargesCommands}));
      }
    });
  }, [getQuote, promoCode]);

  function applyPromoCode(code: string) {
    dispatch(setOrderCustomValues({values: {promoCode: code}}));
    setPromoCode(code);
  }

  const addInsuranceButton = (
    <>
      {t("delivery.additionalServices.addAdditional")} {!isDesktop && <br />}
      {t("delivery.additionalServices.insurance")}
    </>
  );

  const noInsuranceButton = (
    <>
      {t("delivery.additionalServices.noInsurance")} {!isDesktop && <br />}
      {t("delivery.additionalServices.withoutInsurance")}
    </>
  );

  const noInsuranceSubtext = (
    <>{t("delivery.additionalServices.doNotInsure")}</>
  );

  return (
    <Grid xs={6} md={14} sx={{mb: {xs: 0, md: 3}}}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Grid
        container
        columns={{xs: 6, md: 12}}
        justifyContent="space-between"
        direction={{xs: "column", md: "row"}}
      >
        <Grid xs={6} md={6.5}>
          <Grid
            container
            direction="column"
            columns={{xs: 6, md: 6.5}}
            mt={{md: 2, xs: 3}}
          >
            <Grid xs={6} md={6.5}>
              <Typography variant={isDesktop ? "body1" : "caption1"} mb={3}>
                {t("delivery.additionalServices.insureDescriptionStart")}
                <span data-testid="insurance-percent">
                  {isLoading ? (
                    <Grid sx={{display: "inline", mt: 3}}>
                      <CircularProgress size={14} />
                    </Grid>
                  ) : (
                    insurance?.price * 100
                  )}
                </span>
                {t("delivery.additionalServices.insureDescriptionEnd")}
              </Typography>
              <Typography variant={isDesktop ? "h2" : "h3"} mb={2} mt={3}>
                {t("delivery.additionalServices.insureShipment")}
              </Typography>
            </Grid>

            {isLoading ? (
              <Grid sx={{display: "flex", justifyContent: "center", mt: 3}}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                container
                columns={{xs: 6, md: 6}}
                direction={{md: "row", xs: "column"}}
                justifyContent="space-between"
              >
                <Grid xs={6} md={6} mb={2} height="100%">
                  <BoxButton
                    enterAmount={true}
                    data-testid="btn-add-insurance"
                    label={addInsuranceButton}
                    clicked={insuranceState}
                    handleClick={handleClick}
                    insuranceAmount={insuranceAmount}
                    setInsuranceAmount={setInsuranceAmount}
                    setShowFormatValidationError={setShowFormatValidationError}
                    showValidationError={
                      showNumberValidationError ||
                      showFormatValidationError ||
                      showRequiredValidationError
                    }
                  />
                  {(showNumberValidationError ||
                    showFormatValidationError ||
                    showRequiredValidationError) && (
                    <Typography
                      sx={{
                        pt: 0,
                        pb: 0,
                        pl: 2,
                        pr: 2,
                        color: "red",
                      }}
                    >
                      {showFormatValidationError
                        ? t("delivery.additionalServices.formatAmountError")
                        : showNumberValidationError
                        ? t("delivery.additionalServices.enterAmountError")
                        : t("delivery.additionalServices.requiredAmountError")}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  xs={6}
                  md={6}
                  height="100%"
                  data-testid="btn-no-insurance"
                >
                  <BoxButton
                    label={noInsuranceButton}
                    subText={noInsuranceSubtext}
                    clicked={!insuranceState}
                    handleClick={handleClick}
                  />
                </Grid>
              </Grid>
            )}
            {additionalServices && additionalServices.length > 0 && (
              <Grid xs={6} md={6.5}>
                <Typography variant={isDesktop ? "h1" : "h3"} mb={2} mt={3}>
                  {t("delivery.statusBar.additionalServices")}
                </Typography>

                <Grid xs={6} md={6.5} sx={{mb: {xs: 2, md: 5}}}>
                  <AdditionalServicesListParcels
                    additionalServices={additionalServices}
                    isLoadingAdditionalServices={isLoadingAdditionalServices}
                    isLoadingCurrency={isLoadingCurrency}
                    insuranceAccountingItemCode={
                      config?.insuranceAccountingItemCode
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid md={2} xs={6} sx={{py: {xs: 2, md: 0}, mt: 5}}>
            <Button
              disabled={isLoading}
              data-testid="btn-continue-additional-services"
              fullWidth
              type="button"
              variant="contained"
              color="secondary"
              onClick={handleContinue}
              sx={{
                p: 1,
                mb: 3,
              }}
            >
              {t("btnContinue")}
            </Button>
          </Grid>
        </Grid>

        <Grid xs={6} md={5} sx={{mt: {xs: 2, md: 2}, mb: {xs: 0, md: 2}}}>
          {isLoading ? (
            <Grid sx={{display: "flex", justifyContent: "center", mt: 3}}>
              <CircularProgress />
            </Grid>
          ) : (
            <Box sx={{position: "sticky", top: "100px"}}>
              <TotalCostParcels
                deliveryCostPerUnit={deliveryCostPerUnit(
                  thisOrder,
                  t,
                  config?.showTotalCost,
                )}
                charges={calculatedCharges}
                showTotalCost={config?.showTotalCost}
                insuranceItemCode={config?.insuranceAccountingItemCode}
                applyPromoCode={applyPromoCode}
                currentOrder={thisOrder}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
