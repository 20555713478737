import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ContactPaymentMethodDto} from "../paymentMethod/paymentMethod-api";
import {
  CreateChargeCommand,
  CreateCommodityPortalCommand,
  CreateConsigneeAddressCommandValues,
  CreateConsigneeCommandValues,
  CreateOrderPortalCommand,
  OrderTypes,
} from "./order-api";
import {IDeliveryMethod} from "../../Delivery/DeliveryMethod/components/interfaces";
import {compareArrays} from "../../../utils/compareArrays.utils";

export type ShipmentInitialState = {
  ordersData: OrderStepData[];
};

export type OrderSlice = CreateOrderPortalCommand & {
  deliveryMethod?: IDeliveryMethod | null;
  deliveryToTrtMethod?: IDeliveryMethod | null;
  isPickupLocation?: boolean | null;
  customValues?: any | null;
  customValuesMap?: any | null;
  contactPaymentMethod?: ContactPaymentMethodDto | null;
  needInsurance?: boolean;
  containerCommodities?: CreateCommodityPortalCommand[] | null;
  charges?: CreateChargeCommand[] | null;
  contactValues?: CreateConsigneeCommandValues | null;
  contactAddressValues?: CreateConsigneeAddressCommandValues | null;
  container?: CreateCommodityPortalCommand;
};

export type DraftInfo = {
  purchaseTrackingNumber: any;
  draftOrderNumber: any;
};

export type OrderStepData = {
  completedSteps?: {
    [step: string]: boolean;
  };
  order: OrderSlice;
  orderIds: number[];
  isNewDraft: boolean;
  loadedDraft?: any;
  currentDraftInfo?: DraftInfo;
  isConsolidated?: boolean;
};

const initialState: ShipmentInitialState = {
  ordersData: [],
};

const initialOrder: OrderSlice = {
  orderType: OrderTypes?.parcelShipment,
  consigneeValues: {},
  consigneeAddressValues: {},
  commodities: [],
  charges: [],
  chargesTotalPrice: 0,
  customValues: {
    autoPay: false,
  },
};

const initialOrderStepData: OrderStepData = {
  completedSteps: {},
  order: initialOrder,
  orderIds: [],
  isNewDraft: false,
  loadedDraft: null,
};

export const parcelShipmentSlice = createSlice({
  initialState,
  name: "parcelShipmentSlice",
  reducers: {
    logout: () => {
      return initialState;
    },
    initOrder: (
      state: ShipmentInitialState,
      action: PayloadAction<{orderIds: number[]}>,
    ) => {
      const {orderIds} = action.payload;

      const newOrdersDataValue = state.ordersData.filter(
        (x) => !compareArrays(x.orderIds, orderIds),
      );
      const newOrderStep: OrderStepData = {
        ...initialOrderStepData,
        orderIds: orderIds,
      };
      state.ordersData = [...newOrdersDataValue, newOrderStep];
    },
    setStepState: (
      state: ShipmentInitialState,
      action: PayloadAction<{
        orderIds: number[];
        step: string;
        isCompleted: boolean;
      }>,
    ) => {
      const {orderIds, step, isCompleted} = action.payload;
      const existingOrderStep = state.ordersData?.find((x) =>
        compareArrays(x.orderIds, orderIds),
      );
      if (existingOrderStep) {
        existingOrderStep.completedSteps = {
          ...existingOrderStep.completedSteps,
          [step]: isCompleted,
        };
      }
    },
    setStepsState: (
      state: ShipmentInitialState,
      action: PayloadAction<{
        orderIds: number[];
        completedSteps: {
          [step: number]: boolean;
        };
      }>,
    ) => {
      const {orderIds, completedSteps} = action.payload;
      const existingOrderStep = state.ordersData?.find((x) =>
        compareArrays(x.orderIds, orderIds),
      );
      if (existingOrderStep) {
        existingOrderStep.completedSteps = completedSteps;
      }
    },
    setStepOrderIds: (
      state: ShipmentInitialState,
      action: PayloadAction<{
        previousOrderIds: number[];
        newOrderIds: number[];
      }>,
    ) => {
      const {previousOrderIds, newOrderIds} = action.payload;
      const existingOrderStep = state.ordersData?.find((x) =>
        compareArrays(x.orderIds, previousOrderIds),
      );
      if (existingOrderStep) {
        existingOrderStep.orderIds = newOrderIds;
      }
    },
    removeOrderStep: (
      state: ShipmentInitialState,
      action: PayloadAction<number[]>,
    ) => {
      const orderIds = action.payload;
      const newOrdersDataValue = state.ordersData.filter(
        (x) => !compareArrays(x.orderIds, orderIds),
      );
      state.ordersData = [...newOrdersDataValue];
    },
    setDeliveryAddress: (
      state: ShipmentInitialState,
      action: PayloadAction<{
        orderIds: number[];
        consignee: CreateConsigneeCommandValues;
        consigneeAddress: CreateConsigneeAddressCommandValues & {
          addressLine?: string | null;
          isPickupLocation?: boolean | null;
        };
      }>,
    ) => {
      const {orderIds, consignee, consigneeAddress} = action.payload;
      const orderStepData = state.ordersData.find((x) =>
        compareArrays(x.orderIds, orderIds),
      );
      if (orderStepData?.order) {
        orderStepData.order.isPickupLocation =
          consigneeAddress.isPickupLocation || false;
        orderStepData.order.consigneeValues = consignee;
        orderStepData.order.consigneeAddressValues = consigneeAddress;
      }
    },
    setPaymentMethod: (
      state: ShipmentInitialState,
      action: PayloadAction<{
        orderIds: number[];
        paymentMethod: ContactPaymentMethodDto;
        autoPay: boolean;
      }>,
    ) => {
      const {orderIds, paymentMethod, autoPay} = action.payload;
      const orderStepData = state.ordersData.find((x) =>
        compareArrays(x?.orderIds, orderIds),
      );
      if (orderStepData?.order && paymentMethod) {
        orderStepData.order.contactPaymentMethod = paymentMethod;
        orderStepData.order.customValues = {
          ...orderStepData.order.customValues,
          contactPaymentMethod: paymentMethod.contactPaymentMethodId,
          contactPaymentMethodDescription: paymentMethod.description,
          autoPay,
        };
      }
    },
    setCustomerNotes: (
      state: ShipmentInitialState,
      action: PayloadAction<{
        orderIds: number[];
        customerNotes?: string;
      }>,
    ) => {
      const {orderIds, customerNotes} = action.payload;
      const orderStepData = state.ordersData.find((x) =>
        compareArrays(x?.orderIds, orderIds),
      );
      if (orderStepData?.order) {
        orderStepData.order.customValues = {
          ...orderStepData.order.customValues,
          customer_notes: customerNotes,
        };
      }
    },
    deleteOrder: (
      state: ShipmentInitialState,
      action: PayloadAction<{
        orderIds: number[];
      }>,
    ) => {
      const {orderIds} = action.payload;
      state.ordersData = state.ordersData.filter(
        (el) => !compareArrays(el.orderIds, orderIds),
      );
    },
    deleteRelatedOrders: (
      state: ShipmentInitialState,
      action: PayloadAction<{
        orderIds: number[];
      }>,
    ) => {
      const {orderIds} = action.payload;
      state.ordersData = state.ordersData.filter(
        (el) => !el.orderIds?.some((id) => orderIds.includes(id)),
      );
    },
    setDeliveryMethod: (
      state: ShipmentInitialState,
      action: PayloadAction<{
        orderIds: number[];
        deliveryMethod: IDeliveryMethod;
      }>,
    ) => {
      const {orderIds, deliveryMethod} = action.payload;
      const orderStepData = state.ordersData.find((x) =>
        compareArrays(x?.orderIds, orderIds),
      );
      if (orderStepData?.order) {
        orderStepData.order.customValues = {
          ...orderStepData.order.customValues,
          deliveryCostPerUnit: `$${deliveryMethod.price}/${deliveryMethod.unitType}`,
        };
        orderStepData.order.deliveryMethod = deliveryMethod;
      }
    },
    setCommoditiesState: (
      state: ShipmentInitialState,
      action: PayloadAction<{
        orderIds: number[];
        commodity: CreateCommodityPortalCommand | null;
      }>,
    ) => {
      const {orderIds, commodity} = action.payload;
      const orderStepData = state.ordersData.find((x) =>
        compareArrays(x.orderIds, orderIds),
      );
      if (orderStepData?.order && commodity) {
        orderStepData.order.containerCommodities = [commodity];
      }
    },
    setOrderCharges: (
      state: ShipmentInitialState,
      action: PayloadAction<{
        orderIds: number[];
        charges: CreateChargeCommand[];
      }>,
    ) => {
      const {orderIds, charges} = action.payload;
      const orderStepData = state.ordersData.find((x) =>
        compareArrays(x?.orderIds, orderIds),
      );
      if (orderStepData?.order) {
        orderStepData.order.charges = charges;
      }
    },
    setIsNewDraft: (
      state: ShipmentInitialState,
      action: PayloadAction<{
        orderIds: number[];
        isNewDraft: boolean;
      }>,
    ) => {
      const {orderIds, isNewDraft} = action.payload;
      const orderStepData = state.ordersData.find((x) =>
        compareArrays(x?.orderIds, orderIds),
      );
      if (orderStepData) {
        orderStepData.isNewDraft = isNewDraft;
      }
    },
    loadStateFromDraft: (
      state: ShipmentInitialState,
      action: PayloadAction<{
        orderIds: number[];
        draft: OrderStepData;
      }>,
    ) => {
      const {orderIds, draft: draftData} = action.payload;
      const orderStepData = state?.ordersData?.find((x: any) =>
        compareArrays(x?.orderIds, orderIds),
      );

      if (orderStepData) {
        state.ordersData = state?.ordersData?.filter(
          (x) => !compareArrays(x?.orderIds, orderIds),
        );
      }

      state.ordersData = [
        ...state.ordersData,
        {...initialOrderStepData, ...draftData},
      ];
    },
    setCurrentDraftInfo: (
      state: ShipmentInitialState,
      action: PayloadAction<{
        orderIds: number[];
        draftInfo: DraftInfo;
      }>,
    ) => {
      const {orderIds, draftInfo} = action.payload;
      const orderStepData = state?.ordersData?.find((x: any) =>
        compareArrays(x?.orderIds, orderIds),
      );

      if (orderStepData && draftInfo) {
        orderStepData.currentDraftInfo = {
          ...orderStepData?.currentDraftInfo,
          ...draftInfo,
        };
      }
    },
    setPromoCode: (
      state: ShipmentInitialState,
      action: PayloadAction<{
        orderIds: number[];
        promoCode: string;
      }>,
    ) => {
      const {orderIds, promoCode} = action.payload;
      const orderStepData = state.ordersData.find((x) =>
        compareArrays(x?.orderIds, orderIds),
      );
      if (orderStepData?.order) {
        orderStepData.order.customValues = {
          ...orderStepData.order.customValues,
          promoCode,
        };
      }
    },
  },
});

export default parcelShipmentSlice.reducer;

export const {
  logout,
  initOrder,
  setStepState,
  removeOrderStep,
  setDeliveryAddress,
  setOrderCharges,
  setPaymentMethod,
  deleteOrder,
  setDeliveryMethod,
  setCommoditiesState,
  deleteRelatedOrders,
  setStepOrderIds,
  setCustomerNotes,
  setIsNewDraft,
  loadStateFromDraft,
  setCurrentDraftInfo,
  setPromoCode,
} = parcelShipmentSlice.actions;
